import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
    body,
    html,
    a {
        font-family: Arial, sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #4a4a4a;
    }

    input,
    textarea {
        border-radius: 8px;
        border: 1px solid #d4d4d4;
        background: #ffffff;
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
        font-family: Arial, sans-serif;
        color: #000000;

        :focus-within {
            border-color: #4a4a4a;
            box-shadow: 0 0 0 2px rgba(74, 74, 74, 0.2);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Arial, sans-serif;
        color: #000000;
        font-weight: bold;
    }

    h1 {
        font-size: 2.5rem;
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 2rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1.75rem;
        }
    }

    h2 {
        font-size: 2rem;
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 1.75rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 1.75rem;
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 1.5rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1.25rem;
        }
    }

    h4 {
        font-size: 1.5rem;
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 1.25rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1.1rem;
        }
    }

    h5, h6 {
        font-size: 3rem;
        line-height: 1.2;

        @media only screen and (max-width: 890px) {
          font-size: 1.1rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1rem;
        }
    }

    p {
        color: #000000;
        font-size: 1.1rem;        
        line-height: 1.5;
        font-family: Arial, sans-serif;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #000000;

        :hover {
            color: #4a4a4a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;