import React from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  FooterContainer,
  SocialLink,
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={24}>
              <Title>{t("Contact")}</Title>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:contact@framez.ai">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={6} md={6} sm={12} xs={24}>
              <Title>{t("Policy")}</Title>
              <Large to="/">{t("Application Security")}</Large>
              <Large to="/">{t("AI Fairness")}</Large>
            </Col>
            <Col lg={9} md={9} sm={9} xs={24}>
              <Title>{t("Company")}</Title>
              <Para>
                {t("Faster Frames Corporation")}
              </Para>
              <Para>
                {t("Cupertino, California")}
              </Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row justify="space-between" align="middle">
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="FramezCorpLogoBlackBold.svg"
                  aria-label="homepage"
                  width="200px"
                  height="75px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink href="https://twitter.com/fasterframez" target="_blank" rel="noopener noreferrer">
                <SvgIcon src="twitter.svg" aria-label="Twitter" width="24px" height="24px" />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/framez-ai" target="_blank" rel="noopener noreferrer">
                <SvgIcon src="linkedin.svg" aria-label="LinkedIn" width="24px" height="24px" />
              </SocialLink>
            </FooterContainer>
          </Row>
          <Row justify="space-between" align="middle">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Para>
                {t(
                  `© ${new Date().getFullYear()} Faster Frames Corporation. All rights reserved`
                )}
              </Para>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);