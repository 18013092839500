import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 1.5rem;
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;

export const Extra = styled("section")`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid #e8e8e8;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("div")`
  color: #000000;
  font-size: 16px;
  width: 70%;
  margin-bottom: 1rem;
`;

export const Large = styled(Link)`
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  &:hover {
    color: rgb(255, 130, 92);
  }
`;

export const Chat = styled("p")`
  color: #000000;
  max-width: fit-content;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-bottom: 1px solid rgb(255, 130, 92);
    color: rgb(255, 130, 92);
  }
`;

export const FooterContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 769px) {
    justify-content: center;
  }
`;
// Add this to your existing styles
export const SocialLink = styled("a")`
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.1);
  }
`;
