import React from "react";
import styled from "styled-components";
import { SvgIconProps } from "../types";

const StyledImg = styled.img<SvgIconProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
`;

export const SvgIcon: React.FC<SvgIconProps> = ({ src, width, height }) => (
  <StyledImg src={`/img/svg/${src}`} alt={src} width={width} height={height} />
);