import styled from "styled-components";

export const StyledButton = styled("button")<{ color?: string }>`
  background: ${(p) => p.color || "#000000"}; // Changed to black
  color: #ffffff; // White text for better contrast on black background
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #000000; // Black border to match background
  border-radius: 8px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);

  &:hover,
  &:active,
  &:focus {
    color: #ffffff; // Keeping text white for contrast
    border: 1px solid #4a4a4a; // Grey border on hover
    background-color: #4a4a4a; // Grey background on hover
  }
`;